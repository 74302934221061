
import Typed from 'typed.js';
export default {
  name: 'HeroTypedWords',
  props: ['fixedText', 'words'],
  data() {
    return { typed: false }
  },
  mounted() {
    setTimeout(()=>{
      if (!this.typed) {
        const strings = this.words.map(w => w.word)
        this.typed = new Typed('#hero__words', {
          strings: strings,
          typeSpeed: 50,
          loop: true
        })
      }
    },100)
  }
}
