
export default {
  name: 'Hero',
  props: [
    'src',
    'alt',
    'pretitle',
    'title',
    'words',
    'subtitle',
    'position',
    'positionMobile',
    'video',
    'videos',
    'anchor',
    'maxwidth',
    'lightTheme',
    'noOverlay',
    'whiteOverlay',
    'ctatext',
    'ctalink',
    'ctavideo',
    'slides',
    'html',
    'heroClass',
    'heroContentClass',
    'isIndex',
    'isHeroWide',
    'asideImage'
  ],
  data() {
    return {
      videoPercentage: 0,
      // progress: null,
      videoUrl: null,
      videoIndex: 0,
      popupVideoUrl: null,
      loadingVideo: true,
    }
  },
  mounted() {
    if (this.videos && this.videos.length) {
      this.videoUrl = this.videos[0].bgurl
      this.popupVideoUrl = this.videos[0].url && this.videos[0].bgurl
    } else if (this.video && this.video.url) {
      this.videoUrl = this.video.url
    }
    // if (this.videoUrl) {
    //   this.progress = setInterval(() => {
    //     this.videoPercentage = Math.floor(
    //       (100 / this.$refs.bgVideo.duration) * this.$refs.bgVideo.currentTime
    //     )
    //   }, 100)
    // }
  },
  // beforeDestroy() {
  //   if (this.progress) {
  //     clearInterval(this.progress)
  //   }
  // },
  methods: {
    changeVideo(v, index) {
      this.loadingVideo = false
      if (!v && this.videos) {
        this.videoIndex === this.videos.length - 1
          ? (this.videoIndex = 0)
          : this.videoIndex++
      } else {
        this.videoIndex = index
      }
      v = this.videos[this.videoIndex]
      this.videoUrl = v.bgurl
      this.popupVideoUrl = v.url || v.bgurl
      setTimeout(() => {
        this.$refs.bgVideo.load()
        this.$refs.bgVideo.play()
        this.loadingVideo = true
      }, 500)
    },
  },
}
